.container-btn {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
  perspective: 1000;
  backface-visibility: hidden;
  background: #fff;
}

.pulse-button {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 100px;
  height: 100px;
  font-size: 1.3em;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 100px;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: #5a99d4;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);

    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  100% {
    transform: scale(0.9);

    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--first-color) !important;
}
.MuiButton-containedPrimary {
  background-color: var(--first-color) !important;
}
.start__input {
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn--intro {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: var(--mb-1);
}
.intro--getStarted {
  place-self: center;
}
