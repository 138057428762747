/*.todosStyle {
  margin: 20px auto;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
}
.rdrDateRangePickerWrapper {
  display: flex !important;
  flex-wrap: nowrap !important;
}*/
.todo__content {
  display: flex;
  flex-direction: column;
}
.todo__content--header {
  display: flex;
}

.todo__content--headerLeft {
  display: flex;
  flex-direction: column;
}
.todo__content--headerRight {
  margin-left: auto;
}

.todo__content--headerTitle {
  font-weight: var(--font-semi-bold);
  font-size: var(--h4-font-size);
}

.todo__content--headerSubtitle {
  font-size: var(--small-font-size);
}
.todo__datePicker {
  padding: 1rem;
  background-color: var(--input-color) !important;
  transition: 1s;
  border-radius: 0.5rem;
}
.content-hide {
  display: none;
}

.content-show {
  display: flex;
  justify-content: center;
}

.todo__list {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: dense;
}
.todo__list.reverse {
  direction: rtl;
}

.todo__total-minutes {
  text-align: end;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
  /*.rdrDateRangePickerWrapper {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  /*.rdrDefinedRangesWrapper {
    display: none !important;
  }

  .rdrDefinedRangesWrapper {
    display: flex !important;
    justify-content: space-evenly !important;
    width: 100% !important;
  }
  .rdrStaticRanges {
    position: relative !important;
    flex-direction: row !important;
  }
  .rdrInputRanges {
    display: none !important;
  }
  .rdrStaticRangeLabel {
    padding: 0.2rem !important;
    height: 100% !important;
    background-color: var(--first-color);
    font-size: 10px;
    border-radius: 0.5rem;
    margin: 0.1rem;
    color: #fff;
  }*/
}
@media (max-width: 380px) {
}
