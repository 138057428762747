.todoStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
  font-size: var(--small-font-size);
}
.isCompleted {
  color: green;
}
.isPlaying {
  color: red;
}
.isRunning {
  color: var(--first-color);
}
.notPlaying {
  color: blueviolet;
}
.checked {
  text-decoration: line-through;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
  .todoStyle {
    flex-wrap: wrap;
  }
}
@media (max-width: 380px) {
  .outoftime {
    color: red;
  }
}
