@import url(http://fonts.googleapis.com/css2?family=Poppins&display=swap);
/*@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");*/
:root {
  --header-height: 3rem;

  /* Colors */
  /*Purple 250 - green 142 pink 340*/
  --hue-color: 246;

  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --first-color-lighter-lite: hsl(var(--hue-color), 92%, 95%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

  /* Front and typography*/

  --body-font: "Poppins", sans-serif;

  /* .5rem=8px , 1rem=16px ,1.5rem = 24px*/
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.65rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-add-button: 50;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*========== Variables Dark theme ==========*/

body.dark-theme {
  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 12%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

/*========== Button Dark/Light ==========*/

.nav__btns {
  display: flex;
  align-items: center;
}
.change-theme {
  font-size: 1.25rem;
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
  margin-right: 1rem;
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
/*==================== BASE ====================*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 3rem 0;
  margin: 0 0 var(--header-height) 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  background-color: hsl(246, 60%, 99%);
  background-color: var(--body-color);
  color: hsl(246, 8%, 45%);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/

.section {
  padding: 1rem 0 4rem;
}
.section__hello {
  padding: 1rem 0 4rem;
}
.section__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
}

.section__subtitle {
  display: block;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  /* margin-bottom: var(--mb-3); */
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-0-5);
}

.section__title,
.section__subtitle {
  text-align: center;
}

.section__subtitle__finish {
  display: block;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  /* margin-bottom: var(--mb-3); */
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-0-5);
}

.section__title,
.section__subtitle__finish {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: 1.5rem;
  margin-left: var(--mb-1-5);
  margin-right: 1.5rem;
  margin-right: var(--mb-1-5);
}
.grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: hsl(246, 60%, 99%);
  background-color: var(--body-color);
}
/*==================== NAV ====================*/
.nav {
  max-width: 98%;
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo,
.nav__toggle {
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.nav__logo:hover {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
}
.nav__toggle:hover {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}

.nav__logo--xs,
.nav__logo--lg {
  position: relative;
  object-fit: contain;
  height: 3rem;
  height: var(--header-height);
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: hsl(246, 60%, 99%);
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
  .nav__logo--xs {
    display: block;
  }
  .nav__logo--lg {
    display: none;
  }
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}
.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  text-align: center;
}
.nav__link:hover {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
.nav__icon {
  font-size: 1.2rem;
}
.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
.nav__close:hover {
  color: hsl(246, 57%, 53%);
  color: var(--first-color-alt);
}

/* show menu */
.show-menu {
  bottom: 0;
}
/* Active link */
.active-link {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/*==================== HOME ====================*/
.home__container {
  grid-gap: 1rem;
  gap: 1rem;
}
.home__content {
  grid-template-columns: 0.5fr 3fr;
  padding-top: 3.5rem;
  align-items: center;
}
.home__social {
  display: grid;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}
.home__social-icon {
  font-size: 1.25rem;
}
.home__social-icon:hover {
  color: hsl(246, 57%, 53%);
  color: var(--first-color-alt);
}
.home__blob {
  width: 200px;
  fill: hsl(246, 69%, 61%);
  fill: var(--first-color);
}
.home__blob-img {
  width: 170px;
  border-radius: 50%;
}
.home__data {
  grid-column: 1/3;
}
.home__title {
  font-size: 2rem;
  font-size: var(--big-font-size);
}
.home__subtitle {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  color: hsl(246, 8%, 45%);
  color: var(--text-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-bottom: 0.75rem;
  margin-bottom: var(--mb-0-75);
}
.home__description {
  margin-bottom: 2rem;
  margin-bottom: var(--mb-2);
}
.home__scroll {
  /* display: none; */
}
.home__scroll-button {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
  transition: 3s;
}
.home__scroll-button:hover {
  transform: translateY(0.25rem);
}
.home__scroll-mouse {
  font-size: 2rem;
}
.home__scroll-name {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-right: 0.25rem;
  margin-right: var(--mb-0-25);
}
.home__scroll-arrow {
  font-size: 1.25rem;
}
/*==================== BUTTONS ====================*/

.button {
  display: inline-block;
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-weight: var(--font-medium);
  border: none;
  outline: none;
}
.button:hover {
  cursor: pointer;
  background-color: hsl(246, 57%, 53%);
  background-color: var(--first-color-alt);
}
.button__icon {
  font-size: 1.25rem;
  margin-left: 0.5rem;
  margin-left: var(--mb-0-5);
  transition: 0.3s;
}

.button--white {
  background-color: #fff;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}

.button--white:hover {
  background-color: #fff;
}
.button--flex {
  display: inline-flex;
  align-items: center;
}

.button--small {
  padding: 0.75rem 1rem;
}

.button--link {
  padding: 0;
  background-color: transparent;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}

.button--link:hover {
  background-color: transparent;
  color: hsl(246, 57%, 53%);
  color: var(--first-color-alt);
}

.button__add {
  position: fixed;
  height: 60px;
  line-height: 60px;
  width: 60px;
  font-size: 2em;
  font-weight: bold;
  border-radius: 50%;
  background-color: hsl(246, 57%, 53%);
  background-color: var(--first-color-alt);
  color: white;
  text-align: center;
  cursor: pointer;
  bottom: 4rem;
  right: 1rem;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  z-index: 50;
  z-index: var(--z-add-button);
}

.button__submit {
  font-size: 1rem;
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  width: 100%;
}

/*https://www.youtube.com/watch?v=27JtRAI3QO8*/
/*==================== ABOUT ====================*/
.about__img {
  width: 200px;
  border-radius: 0.5rem;
  justify-self: center;
  align-self: center;
}
.about__description {
  text-align: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}
.about__info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}

.about__info-title {
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
}
.about__info-name {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
}

.about__info-title,
.about__info-name {
  display: block;
  text-align: center;
}
.about__buttons {
  display: flex;
  justify-content: center;
}
/*==================== SKILLS ====================*/
.skills__container {
  grid-row-gap: 0;
  row-gap: 0;
}
.skills__header {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
  cursor: pointer;
}

.skills__icon,
.skills__arrow {
  font-size: 2rem;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}

.skills__icon {
  margin-right: 0.75rem;
  margin-right: var(--mb-0-75);
}
.skills__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
}

.skills__subtitle {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 65%);
  color: var(--text-color-light);
}
.skills__arrow {
  margin-left: auto;
  transition: 0.4s;
}
.skills__list {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
  padding-left: 2.7rem;
}
.skills__titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-0-5);
}
.skills__name {
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}
.skills__bar,
.skills__percentage {
  height: 5px;
  border-radius: 0.25rem;
}
.skills__bar {
  background-color: hsl(246, 92%, 85%);
  background-color: var(--first-color-lighter);
}

.skills__percentage {
  display: block;
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color);
}

.skills__html {
  width: 90%;
}
.skills__css {
  width: 80%;
}
.skills__js {
  width: 70%;
}
.skills__react {
  width: 90%;
}

.skills__php {
  width: 90%;
}
.skills__node {
  width: 80%;
}
.skills__java {
  width: 80%;
}

.skills__ps {
  width: 70%;
}
.skills__lt {
  width: 70%;
}

.skills__close .skills__list {
  height: 0;
  overflow: hidden;
}

.skills__open .skills__list {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}
.skills__open .skills__arrow {
  transform: rotate(-180deg);
}
/*==================== QUALIFICATION ====================*/

.qualification__tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  cursor: pointer;
}
.qualification__button:hover {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
.qualification__icon {
  font-size: 1.8rem;
  margin-bottom: 0.25rem;
  margin-bottom: var(--mb-0-25);
}
.qualification__data {
  display: grid;
  grid-template-columns: 1fr -webkit-max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}
.qualification__title {
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}
.qualification__subtitle {
  display: inline-block;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
}
.qualification__calendar {
  font-size: 0.65rem;
  font-size: var(--smaller-font-size);
  color: hsl(246, 8%, 65%);
  color: var(--text-color-light);
}
.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color);
  border-radius: 50%;
}
.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color);
  transform: translate(6px, -7px);
}

.qualification [data-content] {
  display: none;
}
.qualification__active[data-content] {
  display: block;
}

.qualification__button.qualification__active {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
/*==================== SERVICES ====================*/

.services__container {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.services__content {
  position: relative;
  background-color: #fff;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.services__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.services__icon {
  display: block;
  font-size: 1.5rem;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
  font-weight: 500;
  font-weight: var(--font-medium);
}
.services__button {
  cursor: pointer;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
}
.services__button:hover .button__icon {
  transform: translateX(0.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding: 1rem;
  z-index: 1000;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  overflow-y: scroll;
}
.services__modal-content {
  position: relative;
  margin-bottom: 1rem;
  background-color: #fff;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
  z-index: 2000;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.services__modal-services {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}
.services__modal-service {
  display: flex;
}

.services__modal-title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-1-5);
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
  cursor: pointer;
}

.services__modal-icon {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
  margin-right: 0.25rem;
  margin-right: var(--mb-0-25);
}
/* Active Modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}

/*==================== PORTFOLIO ====================*/

.portfolio__container {
  overflow: visible !important;
  overflow: initial !important;
}

.portfolio__content {
  padding: 0 1.5rem !important;
}

.portfolio__img {
  width: 265px !important;
  border-radius: 0.5rem !important;
  justify-self: center !important;
}
.portfolio__title {
  font-size: 1.125rem !important;
  font-size: var(--h3-font-size) !important;
  margin-bottom: 0.5rem !important;
  margin-bottom: var(--mb-0-5) !important;
}
.portfolio__description {
  margin-bottom: 0.75rem !important;
  margin-bottom: var(--mb-0-75) !important;
}
.portfolio__button:hover .button__icon {
  transform: translateX(0.25rem) !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "" !important;
}

.swiper-portfolio-icon {
  font-size: 2rem !important;
  color: hsl(246, 69%, 61%) !important;
  color: var(--first-color) !important;
}
/* .swiper-button-prev {
  left: -0.2rem !important;
}
.swiper-button-next {
  right: -0.2rem !important;
} */

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -2.5rem !important;
}
.swiper-pagination-bullet-active {
  background-color: hsl(246, 69%, 61%) !important;
  background-color: var(--first-color) !important;
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet {
  outline: none !important;
}

/*==================== PROJECT IN MIND ====================*/

.project {
  text-align: center;
}

.project__bg {
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color-second);
  padding-top: 3rem;
}

.project__title {
  font-size: 1.25rem;
  font-size: var(--h2-font-size);
  margin-bottom: 0.75rem;
  margin-bottom: var(--mb-0-75);
}

.project__description {
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-1-5);
}

.project__title,
.project__description {
  color: white;
}

.project__img {
  width: 232px;
  justify-self: center;
}
/*==================== TESTIMONIAL ====================*/

.testimonial__data,
.testimonial__header {
  display: flex;
}

.testimonial__data {
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-bottom: var(--mb-1);
}

.testimonial__img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 0.75rem;
  margin-right: var(--mb-0-75);
}

.testimonial__name {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.testimonial__client {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 65%);
  color: var(--text-color-light);
}

.testimonial__description {
  margin-bottom: 2.5rem;
  margin-bottom: var(--mb-2-5);
}

.testimonial__icon-star {
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
}
/*==================== CONTACT ME ====================*/
.contact__container {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.contact__information {
  display: flex;
  margin-bottom: 2rem;
  margin-bottom: var(--mb-2);
}

.contact__icon {
  font-size: 2rem;
  color: hsl(246, 69%, 61%);
  color: var(--first-color);
  margin-right: 0.75rem;
  margin-right: var(--mb-0-75);
}

.contact__title {
  font-size: 1.125rem;
  font-size: var(--h3-font-size);
}
.contact__subtitle {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 65%);
  color: var(--text-color-light);
}

.contact__content {
  background-color: hsl(246, 70%, 96%);
  background-color: var(--input-color);
  border-radius: 0.5rem;
  padding: 0.75rem;
}

.contact__label {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 15%);
  color: var(--title-color);
}

.contact__input {
  width: 100%;
  background-color: hsl(246, 70%, 96%);
  background-color: var(--input-color);
  color: hsl(246, 8%, 45%);
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem 0.5rem 0;
}

/*==================== FOOTER ====================*/

.footer {
  padding-top: 2rem;
}
.footer__container {
  grid-row-gap: 3.5rem;
  row-gap: 3.5rem;
}

.footer__bg {
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
  margin-bottom: 0.25rem;
  margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
  font-size: 0.813rem;
  font-size: var(--small-font-size);
}

.footer__link {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.footer__link:hover {
  color: hsl(246, 92%, 85%);
  color: var(--first-color-lighter);
}

.footer__social {
  font-size: 1.25rem;
  margin-right: 1.5rem;
  margin-right: var(--mb-1-5);
}
.footer__social:hover {
  color: hsl(246, 92%, 85%);
  color: var(--first-color-lighter);
}

.footer__copy {
  font-size: 0.65rem;
  font-size: var(--smaller-font-size);
  text-align: center;
  color: hsl(246, 8%, 65%);
  color: var(--text-color-light);
  margin-top: 3rem;
  margin-top: var(--mb-3);
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social {
  color: #fff;
}
/*========== SCROLL UP ==========*/

.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: hsl(246, 69%, 61%);
  background-color: var(--first-color);
  opacity: 0.8;
  padding: 0 0.3rem;
  border-radius: 0.4rem;
  z-index: 10;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup:hover {
  background-color: hsl(246, 57%, 53%);
  background-color: var(--first-color-alt);
}

.scrollup__icon {
  font-size: 1.5rem;
  color: #fff;
}
/* Show scroll */
.show-scroll {
  bottom: 5rem;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 0.6rem;
  background-color: hsl(246, 12%, 90%);
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(246, 12%, 80%);
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: hsl(246, 8%, 65%);
  background-color: var(--text-color-light);
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px) {
  .container {
    margin-left: 1rem;
    margin-left: var(--mb-1);
    margin-right: 1rem;
    margin-right: var(--mb-1);
  }
  .nav__menu {
    padding: 2rem;
  }
  .nav__list {
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }
  .home__content {
    grid-template-columns: 0.25fr 3fr;
  }
  .home__blob {
    width: 180px;
  }

  .skills__title {
    font-size: 0.938rem;
    font-size: var(--normal-font-size);
  }

  .qualification__data {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .services__container {
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    justify-content: center;
  }
  .services__content {
    padding-right: 3.5rem;
  }
  .services__modal {
    padding: 0 0.5rem;
  }

  .project__img {
    width: 200px;
  }
  .testimonial__data,
  .testimonial__header {
    flex-direction: column;
    align-items: center;
  }
  .testimonial__img {
    margin-right: 0;
    margin-bottom: 0.25rem;
    margin-bottom: var(--mb-0-25);
  }
  .testimonial__data,
  .testimonial__description {
    text-align: center;
  }
}
/* For medium devices */

@media screen and (min-width: 568px) {
  .home__content {
    grid-template-columns: -webkit-max-content 1fr 1fr;
    grid-template-columns: max-content 1fr 1fr;
  }
  .home__data {
    grid-column: initial;
  }
  .home__img {
    order: 1;
    justify-self: center;
  }

  .about__container,
  .skills__container,
  .portfolio__content,
  .project__container,
  .contact__container,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .qualification__sections {
    display: grid;
    grid-template-columns: 0.6fr;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  body {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }
  .section__hello {
    padding: 1rem 0 2rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  .header {
    top: 0;
    bottom: auto;
    bottom: initial;
  }

  .header,
  .main,
  .footer__container {
    padding: 0 1rem;
  }

  .nav {
    height: calc(3rem + 1.5rem);
    height: calc(var(--header-height) + 1.5rem);
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }
  .nav__list {
    display: flex;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .nav__menu {
    margin-left: auto;
  }

  .change-theme {
    margin: 0;
  }
  .home__container {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }
  .home__content {
    padding-top: 5.5rem;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
  .home__blob {
    width: 270px;
  }

  .home__scroll {
    display: block;
  }

  .home__scroll-button {
    margin-left: 3rem;
  }

  .about__container {
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }

  .about__img {
    width: 350px;
  }

  .about__description {
    text-align: left;
    text-align: initial;
  }

  .about__info {
    justify-content: space-between;
  }

  .about__buttons {
    justify-content: initial;
  }

  .qualification__tabs {
    justify-content: center;
  }

  .qualification__button {
    margin: 0 1rem;
    margin: 0 var(--mb-1);
  }

  .qualification__sections {
    grid-template-columns: 0.5fr;
  }

  .services__container {
    grid-template-columns: repeat(3, 218px);
    justify-content: center;
  }

  .services__icon {
    font-size: 2rem;
  }

  .services__content {
    padding: 6rem 0 2rem 2.5rem;
  }
  .services__modal-content {
    width: 450px;
  }

  .portfolio__img {
    width: 320px;
  }
  .portfolio__content {
    align-items: center;
  }

  .project {
    text-align: left;
    text-align: initial;
  }

  .project__bg {
    background: none;
  }

  .project__container {
    background-color: hsl(246, 69%, 61%);
    background-color: var(--first-color-second);
    border-radius: 1rem;
    padding: 3rem 2.5rem 0;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
            column-gap: 3rem;
  }
  .project__data {
    padding-top: 0.8rem;
  }

  .footer__container {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer__bg {
    padding: 3rem 0 3.5rem;
  }

  .footer__links {
    flex-direction: row;
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .footer__socials {
    justify-self: flex-end;
  }
  .footer__copy {
    margin-top: 4.5rem;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .header,
  .main,
  .footer__container {
    padding: 0;
  }

  .home__blob {
    width: 320px;
  }
  .home__social {
    transform: translateX(-6rem);
  }

  .services__container {
    grid-template-columns: repeat(3, 238px);
  }

  .portfolio__content {
    grid-column-gap: 5rem;
    -webkit-column-gap: 5rem;
            column-gap: 5rem;
  }
  .swiper-portfolio-icon {
    font-size: 3.5rem !important;
  }

  .swiper-button-prev {
    left: -3.5rem !important;
  }

  .swiper-button-next {
    right: -3.5rem !important;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -4.5rem;
  }

  .contact__form {
    width: 460px;
  }

  .contact__inputs {
    grid-template-columns: repeat(2, 1fr);
  }

  .nav__logo--xs {
    display: none;
  }
  .nav__logo--lg {
    display: block;
  }

  .petit_tabs {
    position: relative !important;
    top: -30px !important;
    /* z-index: 1000000 !important; */
    width: 35% !important;
    margin-left: auto;
    border-radius: 5px !important;
  }
  .petit_tabs_inside {
    min-width: auto !important;
  }
}
.MuiToolbar-root {
  background-color: hsl(246, 69%, 61%) !important;
  background-color: var(--first-color) !important;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.icon--only {
  padding: 0.5rem;
  font-size: 1.25rem;
}
.icon--white {
  color: #fff;
}
/*Card*/
.my__card {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  display: none;
}

.my__oneCard {
  background-color: #fff;
  background-color: var(--container-color);
  border-radius: 0.5rem !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}
.my__oneCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.my__card-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.popover--container {
  z-index: 10 !important;
  z-index: var(--z-tooltip) !important;
}

.inside--card {
  display: flex;
  font-size: 0.813rem;
  font-size: var(--small-font-size);
  color: hsl(246, 8%, 45%);
  color: var(--text-color);
}
.inside--card-date {
  text-align: right;
  font-size: 0.65rem;
  font-size: var(--smaller-font-size);
  color: hsl(246, 8%, 65%);
  color: var(--text-color-light);
}
.inside__card--note {
  overflow: hidden;
}
.inside--card-right {
  display: flex;
  align-items: center;
  /* background-color: var(--first-color-lighter); */
  padding: 01rem;
  border-radius: 0.5rem;
  justify-content: space-between;
}
.inside--card-caption {
  display: flex;
  align-items: center;
  padding: 1rem 0 0 1rem;
}
.inside--card-icon {
  color: hsl(246, 8%, 65%) !important;
  color: var(--text-color-light) !important;
}

.goal__card-content {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.cardHeader {
  padding-bottom: 0px !important;
}
.cardContent {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/*Accordion*/
.accordion {
  z-index: 10 !important;
  z-index: var(--z-tooltip) !important;
  background-color: hsl(246, 92%, 95%) !important;
  background-color: var(--first-color-lighter-lite) !important;
}
.inside--accordion {
  display: flex;
  flex-direction: column !important;
  grid-gap: 0.5rem;
  padding: 0 !important;
}
/*icon card*/
.makeStyles-expandOpen-20 {
  background-color: hsl(246, 92%, 85%) !important;
  background-color: var(--first-color-lighter) !important;
}

/*TAB*/
.my__tabs {
  background: linear-gradient(
    90deg,
    rgba(244, 117, 160, 1) 0%,
    rgba(82, 75, 145, 1) 100%
  ) !important;
  color: green !important;
  padding-bottom: 0.2rem;
}
.my__tabs__week {
  background: #715394;
  color: green !important;
  padding-bottom: 0.2rem;
}
.my__tab-title {
  color: #fff !important;
}
/*welcome*/
.welcome {
  margin-top: 2rem;

  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.welcome__slides {
  padding: 1rem;
  padding: var(--mb-1);
}
.welcome__slide {
  padding: 2rem;
  padding: var(--mb-2);
}
.list__container {
  display: flex;
  justify-self: center;
  width: 100%;
  max-width: 500px;
  height: 50px;
  background-color: red;
}

.error-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: red;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}

.weekNameRecurrence {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.weekNameRecurrenceIntro {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.restart {
  font-size: 0.813rem !important;
  font-size: var(--small-font-size) !important;
}

.enable-notification {
  display: inline-block;
}

.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  justify-content: space-between;
}
.submitButton {
  margin-left: 20px;
}

.goal__add-Form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.goal__input {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 1rem !important;
  grid-gap: 0.5rem;
}

.goal__color {
  width: 100% !important;
  margin-left: 0.5rem !important;
  margin-bottom: 1rem !important;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .formStyle {
    width: 100%;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  justify-content: space-between;
}
.submitButton {
  margin-left: 20px;
}

.activeGoal {
  display: block !important;
}

.notActiveGoal {
  display: none !important;
}

.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  justify-content: space-between;
}
.submitButton {
  margin-left: 20px;
}

.sameTrimester {
  display: block !important;
}

.notSameTrimester {
  display: none !important;
}

.activeGoal {
  display: block !important;
}

.notActiveGoal {
  display: none !important;
}

.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  height: 300px;
}
.todo__add-Form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.todo__input {
  margin-bottom: 1rem !important;
}
.todos__button {
  background-color: var(--first-color-alt);
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .formStyle {
    width: 100%;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.container-btn {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #fff;
}

.pulse-button {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 100px;
  height: 100px;
  font-size: 1.3em;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 100px;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: #5a99d4;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);

    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  100% {
    transform: scale(0.9);

    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--first-color) !important;
}
.MuiButton-containedPrimary {
  background-color: var(--first-color) !important;
}
.start__input {
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn--intro {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: var(--mb-1);
}
.intro--getStarted {
  place-self: center;
}
.welcome {
  height: 100%;
}

.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__img {
  margin-bottom: 10px;
}

.login__content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-screen__form {
  position: fixed;
  width: 380px;
  height: auto;
  padding: 1.5rem;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff; */
  border-radius: 0.5rem;
}

.login-screen__title {
  text-align: center;
  margin-bottom: 0.5rem;
}

.login-screen__forgotpassword {
  font-size: 0.7rem;
}

.login-screen__subtext {
  font-size: 0.7rem;
  display: block;
  margin: 0.5rem 0;
}

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid var(--first-color);
}

.register-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-screen__form {
  width: 380px;
  height: auto;
  padding: 1.5rem;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff; */
}

.register-screen__title {
  text-align: center;
  margin-bottom: 1rem;
}

.register-screen__subtext {
  font-size: 0.7rem;
  display: block;
  margin: 0.5rem 0;
}

.forgotpassword-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgotpassword-screen__form {
  width: 380px;
  height: auto;
  padding: 1.5rem;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff; */
}

.forgotpassword-screen__title {
  text-align: center;
  margin-bottom: 1rem;
}

.forgotpassword-screen__subtext {
  font-size: 0.7rem;
  display: block;
  margin: 0.5rem 0;
}

.resetpassword-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetpassword-screen__form {
  width: 380px;
  height: auto;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff;
}

.resetpassword-screen__title {
  text-align: center;
  margin-bottom: 1rem;
}

.todoStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
  font-size: var(--small-font-size);
}
.isCompleted {
  color: green;
}
.isPlaying {
  color: red;
}
.isRunning {
  color: var(--first-color);
}
.notPlaying {
  color: blueviolet;
}
.checked {
  text-decoration: line-through;
}


@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
  .todoStyle {
    flex-wrap: wrap;
  }
}
@media (max-width: 380px) {
  .outoftime {
    color: red;
  }
}

/*.todosStyle {
  margin: 20px auto;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
}
.rdrDateRangePickerWrapper {
  display: flex !important;
  flex-wrap: nowrap !important;
}*/
.todo__content {
  display: flex;
  flex-direction: column;
}
.todo__content--header {
  display: flex;
}

.todo__content--headerLeft {
  display: flex;
  flex-direction: column;
}
.todo__content--headerRight {
  margin-left: auto;
}

.todo__content--headerTitle {
  font-weight: var(--font-semi-bold);
  font-size: var(--h4-font-size);
}

.todo__content--headerSubtitle {
  font-size: var(--small-font-size);
}
.todo__datePicker {
  padding: 1rem;
  background-color: var(--input-color) !important;
  transition: 1s;
  border-radius: 0.5rem;
}
.content-hide {
  display: none;
}

.content-show {
  display: flex;
  justify-content: center;
}

.todo__list {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: dense;
}
.todo__list.reverse {
  direction: rtl;
}

.todo__total-minutes {
  text-align: end;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
  /*.rdrDateRangePickerWrapper {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  /*.rdrDefinedRangesWrapper {
    display: none !important;
  }

  .rdrDefinedRangesWrapper {
    display: flex !important;
    justify-content: space-evenly !important;
    width: 100% !important;
  }
  .rdrStaticRanges {
    position: relative !important;
    flex-direction: row !important;
  }
  .rdrInputRanges {
    display: none !important;
  }
  .rdrStaticRangeLabel {
    padding: 0.2rem !important;
    height: 100% !important;
    background-color: var(--first-color);
    font-size: 10px;
    border-radius: 0.5rem;
    margin: 0.1rem;
    color: #fff;
  }*/
}
@media (max-width: 380px) {
}

.mobile__top {
  display: flex;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
  width: auto;
  /* background-color: var(--body-color); */
}

.mobile__title {
  display: flex;
  font-size: var(--h2-font-size);
  flex: 1 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}
/*Buttons */
.button__back {
  font-size: 1rem;
  font-size: var(--font-semi-bold);
  color: var(--title-color) !important;
}
.button__backWhite {
  color: #fff !important;
}
.button__right {
  font-size: 1.5rem;
  color: var(--title-color);
}
.MuiTabs-indicator {
  background-color: #fff !important;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
  .scroll-top {
    width: 100%;
    /* padding-right: 1rem; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
  }
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.container-btn {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #fff;
}

.pulse-button {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 100px;
  height: 100px;
  font-size: 1.3em;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 100px;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: #5a99d4;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);

    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  100% {
    transform: scale(0.9);

    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--first-color) !important;
}
.MuiButton-containedPrimary {
  background-color: var(--first-color) !important;
}
.start__input {
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn--intro {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: var(--mb-1);
}
.intro--getStarted {
  place-self: center;
}

.yearlyStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
}
.isCompleted {
  color: green;
}
.isActive {
  color: red;
}
.notActive {
  color: red;
}
.checked {
  text-decoration: line-through;
}

.yearlyStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
}

.trimesterStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
}
.isCompleted {
  color: green;
}
.isActive {
  color: red;
}
.notActive {
  color: red;
}
.checked {
  text-decoration: line-through;
}

.trimesterStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
}

.weeklyStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
}
.isCompleted {
  color: green;
}
.isActive {
  color: red;
}
.notActive {
  color: #f77f00;
}
.checked {
  text-decoration: line-through;
}


.weeklyStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
}

.sameTrimester {
  display: block !important;
}

.notSameTrimester {
  display: none !important;
}

/* .profile__name {
  display: flex;
  background-color: red;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
} */

.profile__img,
.profile__name {
  text-align: center;
}
.profile__back {
  position: absolute;
  width: 100%;
  height: 30%;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    var(--first-color-lighter) 0%,
    transparent 100%
  );
}

.formStyle {
  margin: 20px auto;
  padding: 20px;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  height: 300px;
}
.todo__add-Form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.todo__input {
  margin-bottom: 1rem !important;
}
.todos__button {
  background-color: var(--first-color-alt);
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
  .formStyle {
    width: 100%;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
}
@media (max-width: 380px) {
}

.todoStyle {
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #bdbdbd;
  border-radius: 9px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.moreStyle {
  color: #8f8f8f;
  font-size: var(--small-font-size);
}
.isCompleted {
  color: green;
}
.isPlaying {
  color: red;
}
.isRunning {
  color: var(--first-color);
}
.notPlaying {
  color: blueviolet;
}
.checked {
  text-decoration: line-through;
}

@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
  .todoStyle {
    flex-wrap: wrap;
  }
}
@media (max-width: 380px) {
  .outoftime {
    color: red;
  }
}

/*.todosStyle {
  margin: 20px auto;
  border-radius: 9px;
  box-shadow: 0px 0px 12px -3px #000000;
}
.rdrDateRangePickerWrapper {
  display: flex !important;
  flex-wrap: nowrap !important;
}*/
.todo__content {
  display: flex;
  flex-direction: column;
}
.todo__content--header {
  display: flex;
}

.todo__content--headerLeft {
  display: flex;
  flex-direction: column;
}
.todo__content--headerRight {
  margin-left: auto;
}

.todo__content--headerTitle {
  font-weight: var(--font-semi-bold);
  font-size: var(--h4-font-size);
}

.todo__content--headerSubtitle {
  font-size: var(--small-font-size);
}
.todo__datePicker {
  padding: 1rem;
  background-color: var(--input-color) !important;
  transition: 1s;
  border-radius: 0.5rem;
}
.content-hide {
  display: none;
}

.content-show {
  display: flex;
  justify-content: center;
}

.todo__list {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: dense;
}
.todo__list.reverse {
  direction: rtl;
}
@media (max-width: 1230px) {
}
@media (max-width: 1100px) {
}
@media (max-width: 900px) {
}
@media (max-width: 868px) {
  /*.rdrDateRangePickerWrapper {
      display: flex !important;
      flex-wrap: wrap !important;
    }
    /*.rdrDefinedRangesWrapper {
      display: none !important;
    }
  
    .rdrDefinedRangesWrapper {
      display: flex !important;
      justify-content: space-evenly !important;
      width: 100% !important;
    }
    .rdrStaticRanges {
      position: relative !important;
      flex-direction: row !important;
    }
    .rdrInputRanges {
      display: none !important;
    }
    .rdrStaticRangeLabel {
      padding: 0.2rem !important;
      height: 100% !important;
      background-color: var(--first-color);
      font-size: 10px;
      border-radius: 0.5rem;
      margin: 0.1rem;
      color: #fff;
    }*/
}
@media (max-width: 380px) {
}

.dashboard__container {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  display: flex;
  align-items: center;
  justify-content: center;
}
.test {
  position: absolute;
  width: 100%;
  height: 15rem;
  z-index: -1;
  top: 0;
  left: 0;

  /* background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  ); */
  /* background: linear-gradient(
    180deg,
    rgba(63, 94, 251, 1) 0,
    rgba(252, 70, 107, 1) 80%,
    transparent
  ); */
}

.text-white {
  color: white;
}

/*header*/
.dashboard__header {
  grid-template-columns: 2fr auto;
}
.dashboard__avatar {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.dashboard__level {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}
.dashboard__points {
  font-size: var(--small-font-size);
}
.progression__container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: var(--smaller-font-size);
}

.progression__dates {
  display: flex;
  justify-content: space-between;
}

.progression__indicator {
  display: flex;
  justify-content: space-between;
}

.progression__bar {
  height: 1rem;
}

.progression__percentage {
  height: 1rem;
}
.summary__rows {
  display: flex;
  grid-gap: 0.5rem;
  overflow-x: auto;
  justify-content: start;
  padding: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.summary__rows::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.summary__rows {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.summary__content {
  position: relative;
  background-color: var(--input-color);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  display: flex;
  justify-content: center;
  min-width: 130px;
  max-width: 200px;
}

.summary__title {
  font-size: var(--h4-font-size);
  margin-bottom: var(--mb-0-5);
  font-weight: var(--font-medium);
  color: var(--text-color);
}

/* activity */
.activity__container {
  margin-top: var(--mb-1);
}
.activity__content {
  background-color: var(--container-color);
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  margin-top: var(--mb-0-5);
}
.activity__subtitle {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.activity__data {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activity__text {
  font-size: var(--font-medium);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}
.activity__icon {
  display: block;
  font-size: 1rem;
  color: var(--text-color-light);
  cursor: pointer;
}
.activity__bar,
.activity__percentage {
  height: 1px;
  border-radius: 0.25rem;
}
.activity__bar {
  background-color: var(--text-color-light);
}

/* For small devices */
@media screen and (max-width: 350px) {
}
/* For medium devices */

@media screen and (min-width: 568px) {
  .summary__rows {
    grid-gap: 1rem;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
}
/* For large devices */
@media screen and (min-width: 1024px) {
}
.CircularProgressbar-path {
  border-radius: 5rem !important;
}
.listtoday {
  max-height: 200px;
  overflow-y: auto;
}

.container-btn {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #fff;
}

.pulse-button {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  display: block;
  width: 100px;
  height: 100px;
  font-size: 1.3em;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 100px;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: #5a99d4;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);

    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  100% {
    transform: scale(0.9);

    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--first-color) !important;
}
.MuiButton-containedPrimary {
  background-color: var(--first-color) !important;
}
.start__input {
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn--intro {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: var(--mb-1);
}
.intro--getStarted {
  place-self: center;
}

