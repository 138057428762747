.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__img {
  margin-bottom: 10px;
}

.login__content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-screen__form {
  position: fixed;
  width: 380px;
  height: auto;
  padding: 1.5rem;
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff; */
  border-radius: 0.5rem;
}

.login-screen__title {
  text-align: center;
  margin-bottom: 0.5rem;
}

.login-screen__forgotpassword {
  font-size: 0.7rem;
}

.login-screen__subtext {
  font-size: 0.7rem;
  display: block;
  margin: 0.5rem 0;
}

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid var(--first-color);
}
